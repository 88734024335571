.slider {
    position: relative;
    height: 500px;
    width: 1200px;
    left: 50%;
    transform: translateX(-50%);
    top: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    margin-bottom: 10%;
}

.image {
    /*height: 600px;*/
    /*width: 1000px;*/
    /*height: 72vh;*/
    /*width: 90vw;*/
    height: 500px;
    width: 500px;
    border-radius: 10px;
    object-fit: contain;
    object-position: center;
    flex: 1;
    /*height: 100%;*/
}

.awardCitation{
    height: 40%;
    width: 600px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: justify;
    flex-wrap: wrap;
    flex-direction: column;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: bolder;
    font-style: oblique;
}

.awardCitation>p:nth-child(1){
    text-align: justify;
}

.awardCitation>p:nth-child(2){
    width: 80%;
    text-align: end;
}

/*.right-arrow {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    right: 100px;*/
/*    font-size: 3rem;*/
/*    color: #000;*/
/*    z-index: 10;*/
/*    cursor: pointer;*/
/*    user-select: none;*/
/*}*/

/*.left-arrow {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 100px;*/
/*    font-size: 3rem;*/
/*    color: #000;*/
/*    z-index: 10;*/
/*    cursor: pointer;*/
/*    user-select: none;*/
/*}*/

.slide {
    justify-content: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    opacity: 0;
    /*transition: 20ms ease-in-out;*/
}

.slide.active {
    justify-content: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    opacity: 1;
    /*transition-duration: 1s;*/
    /*transform: scale(1.08);*/
}



@media only screen and (max-width: 1250px){
    .slider{
        width: 100%;
        justify-content: center;
    }
    .awardCitation{
    }
}

@media only screen and (max-width: 1200px){
    .awardCitation{
        width: 40%;
    }
}

@media only screen and (max-width: 850px){
    .awardCitation{
        height: fit-content;
        padding: 3% 0;
        width: 90%;
        line-height: 1.5;
    }
    .slider{
        top: 0;
        height: fit-content;
        padding: 5% 0;
        flex-direction: column-reverse;
    }
}
@media only screen and (max-width: 500px){
    .image{
        width: 99%;
    }
}
