.videoSectionContainer{
    /*min-height:70vh;*/
    min-height: 600px;
    margin-top: 15%;
    margin-bottom: 15%;
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-wrap: wrap;
    flex-flow: wrap;
}

.sectionContainer{
    /*min-height: 70vh;*/
    min-height: 100%;
    width: 100%;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
}

.titleContainer{
    height: 70vh;
    height: 600px;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color:#d4af37;
    color: black;
    font-family: 'Cinzel Decorative', cursive;
    font-size: 50px;
}

.titleContainer>ul{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.titleContainer>ul>li{
    cursor: pointer;
}

.titleContainer>ul>li:hover{
    color: #085397;
}

.videoPlayerContainer{
    height:70vh;
    height:600px;
    width:60%;
    /*box-shadow: rgba(0, 245, 0, 0.56) 60px 22px 90px 30px;*/
    box-shadow: rgb(8, 83, 151) 10px 10px 90px 10px;
}


@media only screen and (max-width: 1440px){
    .videoSectionContainer{
        /*height: 60vh;*/
        height: 800px;
        width: 100%;
    }
    .titleContainer{
        /*height: 20vh;*/
        height: 180px;
        width: 80vw;
        display: flex;
        flex-direction: row;
        font-size: 30px;
    }
    .titleContainer>ul{
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .sectionContainer{
        height: 100%;
        flex-direction: column;
        width: 100vw;
    }

    .videoPlayerContainer{
        width: 90%;
    }

}

@media only screen and (max-width: 700px){
    .videoSectionContainer{
        /*height: 100vh;*/
        height: 900px;
    }
    .titleContainer{
        width: 100%;
        height: 20%;
    }
    .titleContainer>ul{
        width: 100%;
        flex-direction: column;
    }
}


@media only screen and (max-width: 649px){
    .videoSectionContainer{
        /*height: 80vh;*/
        height: 700px;
    }
    .sectionContainer{
        flex-direction: column-reverse;
        justify-content: space-between;
    }
    .titleContainer{
        /*height: 20vh;*/
        height: 180px;
        width: 100%;
        /*margin: 15% 0;*/
    }
    .titleContainer>ul{
        flex-direction: column;
    }

    .videoPlayerContainer{
        /*height: 50vh;*/
        height: 450px;
    }
}

@media only screen and (max-width: 500px){
    /*.titleContainer {*/
    /*    background-color: #4f005f;*/
    /*    !*height: 20vh;*!*/
    /*}*/
    .sectionContainer{
        justify-content: space-around;
    }
    .videoPlayerContainer{
        /*height: 30vh;*/
        height: 260px;
    }


}


@media only screen and (max-width: 300px){
    .titleContainer {
        /*height: 20vh;*/
        height: 180px;
    }
    .sectionContainer{
        /*height: 20vh;*/
        height: 200px;
    }
    .videoPlayerContainer{
        /*height: 20vh;*/
        height: 180px;
    }


}

