.memberContainer{
    /*min-height: 40vh;*/
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
}

.teamBoxOdd{
    height: 100%;
    width: 80%;
    display: flex;
    /*flex-direction: row-reverse;*/
    justify-content: space-evenly;
    align-items: center;
}

.teamBoxEven{
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
}

.memberPhoto{
    height: fit-content;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    height: 270px;
    width: 250px;

}

.memberText{
    height: 100%;
    width: 70%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.memberName{
    width: 100%;
    height: fit-content;
    font-size: 40px;
    padding: 2% 0;
    text-align: center;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: bolder;
}

.memberDesc{
    width: 100%;
    padding: 0 3%;
    font-size: 1.1rem;
    line-height: 1.2;
    font-family: 'Josefin Sans', sans-serif;
}


.memberDesignation{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 25px;
    font-family: 'Josefin Sans', sans-serif;

}

.memberDesignation>hr{
    height: 1px;
    width: 30%;
    color: #085397;
}


@media only screen and (max-width: 1440px){
    .teamBoxOdd,.teamBoxEven{
        width: 100%;
    }
}

@media only screen and (max-width: 1000px){
    .memberContainer{
        height: 500px;
    }
}

@media only screen and (max-width: 768px){
    .photo{
        height: 200px;
        width: 180px;
    }

    .memberName{
        font-size: 30px;
    }

    .memberContainer{
        height: 900px;
    }

    .teamBoxOdd, .teamBoxEven{
        /*height: 100vh;*/
        flex-direction: column;
        justify-content: space-evenly;
        padding: 5% 0;
    }
}

@media only screen and (max-width: 600px){
    .memberText{
        width: 100%;
        height: 100%;
        justify-content: space-evenly;
    }
    .memberDesc{
        line-height: 1;
    }
    .memberDesignation>hr{
        width: 20%;
    }
}

@media only screen and (max-width: 350px){
    .memberContainer{
        height: 1500px;
    }
    .memberDesignation>hr{
        width: 10%;
    }
}
