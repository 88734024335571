.slider {
    position: relative;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    height: 80vh;
    width: 95vw;
    border-radius: 10px;
    object-fit: contain;
    object-position: center;
    flex: 1;
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: 100px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: 100px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.slide {
    justify-content: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    opacity: 0;
    /*transition: 20ms ease-in-out;*/
}

.slide.active {
    justify-content: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    opacity: 1;
    /*transition-duration: 1s;*/
    /*transform: scale(1.08);*/
}

@media only screen and (max-width: 1024px){
    .right-arrow{
        right: 50px;
    }
    .left-arrow{
        left: 50px;
    }
}

@media only screen and (max-width: 400px){
    .right-arrow{
        right: 25px;
        top: 80%;
    }
    .left-arrow{
        left: 25px;
        top: 80%;
    }
}
