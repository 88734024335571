.aboutUsContainer{
    margin: 2% 0;
    min-height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.heading{
    text-align: center;
    font-family: 'Cinzel Decorative', cursive;
    font-size: 50px;
    text-shadow: 3px 11px 15px rgba(151, 133, 108, 1);
    font-weight: 600;
    width: 90%;
    height: 20%;
    margin-bottom: 3%;
}


.aboutUs{
    min-height: fit-content;
    width: 80%;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 27px;
    font-weight: 550;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutUs>p{
    margin: 1% 0;
    text-align: center;
}

.tagline{
    font-family: 'Cinzel Decorative', cursive;
    font-size: 40px;
    text-shadow: 3px 11px 15px rgba(151, 133, 108, 1);
    width: 100%;
    height: fit-content;
    padding: 2rem;

}

.tagline>p{
    text-align: center;
    cursor: pointer;
}
.tagline>p:hover{
    color: #085397;
}

.caterToContainer{
    height: 800px;
    width: 100%;
    margin: 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.caterBox1{
    /*background-color: #4f005f;*/
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.caterBox2{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.caterToImg{
    height: 80%;
    width: 40%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../assets/WeCaterTo.jpeg");
}

.caterToText{
    height: 90%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
}

.caterToText>p{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 40px;
    font-weight:200;
    letter-spacing: 2px;
    cursor: pointer;
    color: black;
}

/*.caterToText>p:hover{*/
/*    color: #085397;*/
/*}*/

.serviceHeading{
    text-align: center;
    font-family: 'Cinzel Decorative', cursive;
    font-size: 50px;
    text-shadow: 3px 11px 15px rgba(151, 133, 108, 1);
}

.servicesContainer{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-flow: row;
    align-items: center;
    flex-wrap: wrap;
}
.serviceCard{
    height: fit-content;
    margin: 5%;

}



/* Media Queries */

@media only screen and (max-width: 1024px){
    .aboutUsContainer{
        margin: 5% 0;
    }

    .aboutUs{
        width: 90%;
        padding: 5% 0;
    }
    .aboutUs>p{
        margin: 2% 0;
    }

    .caterToText{
        height: 70%;
    }

    .caterToText>p{
        font-size: 30px;
    }

    /*.caterToImg {
        height: 70%;
        width: 50%;
    }*/
}

@media only screen and (max-width: 768px) {
    .aboutUsContainer {
        margin: 2% 0;
    }

    .caterBox2{
        flex-direction: column;
        justify-content: space-between;
    }

    .caterToImg{
        height: 300px;
    }
    .caterToText{
        flex-direction: row;
        flex-grow: 2;
        flex-flow: wrap;
        flex-wrap: wrap;
        height: 50%;
        width: 90%;
        align-items: center;
        justify-content: space-evenly;
    }

    .caterToText>p{
        width: 100%;
        text-align: center;
        margin: 0 8%;
    }

}
@media only screen and (max-width: 570px) {
    .aboutUs{
        width: 100%;
    }
    .aboutUs>p{
        margin: 3% 0;
    }
}

@media only screen and (max-width: 375px) {
    .tagline{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-flow: wrap;
        font-size: 30px;
    }
    .caterBox2{
        margin: 15% 0;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100vh;
        padding: 2% 0;
    }
    /*.caterToImg{
        height: 40%;
        width: 90%;
    } */
    .caterToText{
        margin: 5% 0;
    }
    .caterToText>p{
        font-size: 30px;
    }
    .aboutUsContainer{
        margin-bottom: 15%;
    }
    .heading{
        font-size: 40px;
    }
    .aboutUs>p{
        margin: 5% 0;
        font-size: 20px;
    }
}

