/* Global Fonts */
/*1. Paragraph Text*/
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
/*------------'Josefin Sans', sans-serif------------*/

/*2. Nav Bar Text*/
@import url('https://fonts.googleapis.com/css2?family=Cherry+Swash:wght@700&display=swap');
/*------------'Cherry Swash', cursive------------*/

/*3. Home Page Heading text -> Newsletter + Press Release*/
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700&display=swap');
/*------------'Cinzel Decorative', cursive------------*/

@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
  /*  monospace;*/
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /*background-color: #0D182A;*/
  background-color: white;
}
