.prContainer{
    /*height: 90vh;*/
    /*margin-top: 15%;*/
    /*margin-bottom: 15%;*/
    height: 900px;
    width: 100%;
    /*padding: 1% 3%;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}
.cardContainer{
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content:center;
}
.cardDiv{
    width: 80vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.heading{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20vw;
    font-family: 'Cinzel Decorative', cursive;
    font-size: 50px;
    color: #d4af37;
    color: black;
    text-shadow: 3px 11px 15px rgba(151, 133, 108, 1);

}
.viewMoreContainer{

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*.viewMoreContainer>button{*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border: none;*/
/*    outline: none;*/
/*    background-color: transparent;*/
/*    color: black;*/
/*    cursor: pointer;*/
/*}*/

.linkClass{
    text-decoration: none;
    font-style: italic;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

/* Responsive Config */

@media only screen and (max-width: 1440px) and (min-width: 1100px){
    .prContainer{
        /*margin: 15% 0;*/
        /*height: 70vh;*/
        margin: 50px 0;
        height: 600px;
    }
    .cardContainer{
        width: 100%;
        justify-content:flex-start;
        align-items: start;
    }
    .heading{
        font-size: 30px;
        width: 30%;
    }
}
@media only screen and (max-width: 1100px) and (min-width: 1024px){
    .prContainer{
        /*margin: 10% 0;*/
        /*height: 70vh;*/
        margin: 50px 0;
        height: 600px;
    }
    .cardContainer{
        width: 100%;
        justify-content:flex-start;
        align-items: start;
    }
    .heading{
        margin-left: 3%;

        font-size: 30px;
        width: 30%;
    }
}

/**/
/*@media only screen and (max-width: 1024px) and (min-width: 767px){*/
/*    .prContainer{*/
/*        margin: 10% 0;*/
/*        height: 70vh;*/
/*    }*/
/*    .cardContainer{*/
/*        width: 100%;*/
/*        justify-content:flex-start;*/
/*        align-items: start;*/
/*    }*/
/*    .heading{*/
/*        margin-left: 3%;*/
/*        font-size: 30px;*/
/*        width: 30%;*/
/*    }*/
/*}*/
/*@media only screen and (max-width: 768px) and (min-width: 425px){*/
/*    .prContainer{*/
/*        justify-content: space-evenly;*/
/*        height: 100vh;*/
/*        width: 100%;*/
/*    }*/
/*    .cardContainer{*/
/*        height: 80%;*/
/*        width: 100%;*/
/*        flex-direction: column-reverse;*/
/*        margin-bottom: 5%;*/
/*    }*/
/*    .heading{*/
/*        width: 70%;*/
/*        flex-direction: row;*/
/*        font-size: 50px;*/
/*        justify-content: space-evenly;*/
/*    }*/
/*    .cardDiv{*/
/*        width: 100%;*/
/*    }*/
/*}*/

@media only screen and (max-width: 1024px){
    .prContainer{
        justify-content: space-evenly;
        /*height: 100vh;*/
        width: 100%;
        height: 850px;
    }
    .cardContainer{
        height: 80%;
        width: 100%;
        flex-direction: column-reverse;
        /*margin-bottom: 5%;*/
        margin-bottom: 50px;
    }
    .heading{
        width: 50%;
        flex-direction: row;
        font-size: 50px;
        justify-content: space-evenly;
    }
    .cards{
        width: 100%;
    }
}

@media only screen and (max-width: 650px){
    .heading{
        width: 70%;
        padding: 0 3%;
        font-size: 40px;
        justify-content: space-around;
    }
}



@media only screen and (max-width: 700px) and (min-width: 300px){
    .prContainer{
        /*margin: 15% 0;*/
        margin: 50px 0;
        justify-content: space-around;
        /*height: 110vh;*/
        width: 100%;
        height: 850px;

    }
    .cardContainer{
        width: 100%;
        flex-direction: column-reverse;
    }
    .heading{
        width: 100%;
        height: fit-content;
        flex-direction: column;
        font-size: 40px;
        justify-content: space-around;
        margin-bottom: 15%;
    }
    .cardDiv>div:nth-child(2){
        display: none;
    }
}
@media only screen and (max-width: 300px) and (min-width: 100px){
    .cardDiv{
        width: 100%;
    }
    .prContainer{
        margin-top: 50px;
        justify-content: space-evenly;
        /*height:100vh;*/
        width: 100%;
        height: 800px;
    }
    .cardContainer{
        width: 100%;
        flex-direction: column-reverse;
        justify-content: space-around;
    }
    .heading{
        width: 100%;
        height: fit-content;
        flex-direction: column;
        font-size: 25px;
        justify-content: space-around;
    }
    .cardDiv>div:nth-child(2){
        display: none;
    }
}
