.pdfContainer{
    margin-top: 5%;
    margin-bottom: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}
.prHeadingContainer{
    text-shadow: 3px 11px 15px rgba(151, 133, 108, 1);
    font-family: 'Cinzel Decorative', cursive;
    font-size: 50px;
    color: black;

    width: 100%;
    text-align: center;
    margin-bottom: 10%;
}

.noData{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    color: white;
    font-size: larger;
}

.releaseContainer{
    min-height: 100vh;
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    flex-flow: wrap;
    align-items: flex-start;
    flex-wrap: wrap;
}

.card{
    height: fit-content;
    margin: 5%;
}
