.outerBox{
    background-size: cover;
    background: url("../../assets/car2.jpg") no-repeat;
    min-height: 100vh;
    width: 100vw;
}
.innerBox{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0,0,0,0.5);
}

.contactUsContainer{
    height:50%;
    width: 50vw;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imgContainer{
    height: 50vh;
    width: 50%;
}

.middlePagePart{
    height:100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
}
.sideImg{
    height:100%
}

/* form styling */
form{
    margin-top: 2%;
    width: 100%;
}
.name-email{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.name-email>*{
    width: 42%;
}

.subject,.query{
    margin-top: 2%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.subject>*,
.query>*{
    width: 90%;
}

.btn-container{
    margin-top: 5%;
    width: 95%;
    display: flex;
    justify-content: flex-end;

}
.btn{}
