@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');

/*body {*/
/*    box-sizing: border-box;*/
/*    color: #220f5f;*/
/*    font-family: 'Poppins', sans-serif;*/
/*}*/

.container {
    width: 80%;
    /*max-width: 800px;*/
    padding: 0 10px;
    margin: 0 auto;
    margin-top: 70px;
    color: black;
    margin-bottom: 15%;
}



table {
    border-collapse: collapse;
    width: 100%;
}

tr th {
    font-size: 18px;
    padding: 12px;
    border: 1px solid #eeeeee;
    text-align: left;
    background-color: rgba(217, 221, 146, 0.2);
}

tr td {
    border: 1px solid #eeeeee;
    text-align: left;
}


tr:hover {
    background-color: #fffaf3;
    background-color: #085397;
    color: white;
}
