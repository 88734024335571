*,
*:before,
*:after{
    padding: 0;
    margin:0;
    box-sizing: border-box;
}

.navContainer{
    /*background-color: rgba(0,0,0,0.17);*/
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navImgContainer{
    width: 20%;
    height: 100%;
}


.navOptions{
    position: relative;
    width: 80%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Cherry Swash', cursive;
    font-weight: 100;
    font-size: 25px;
}


.navOptions>a{
    /*color: navy;*/
    color:black;
    position: relative;
    text-decoration: none;
}

.navOptions>a:before,
.navOptions>a:after {
    content: "";
    position: absolute;
    height: 3px;
    width: 0;
    /*background-color: #18f08b;*/
    background-color: #d4af37;
    background-color: #085397;
    transition: 0.5s;
}
.navOptions>a:after {
    left: 0;
    bottom: -10px;
}
.navOptions>a:before {
    right: 0;
    top: -10px;
}
.navOptions>a:hover {
    /*color: #ffffff;*/
    color:black;
}
.navOptions>a:hover:after,
.navOptions>a:hover:before {
    width: 100%;
}



@media only screen and (min-width: 1441px) {
    .navImgContainer{
        width: 25%;
    }
    .navOptions{
        width:75%;
    }

}


@media only screen and (min-width: 1024px) and (max-width: 1440px){
    .navImgContainer{
        width: 40%;
    }
    .navOptions{
        width: 60%;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1023px){
    .navImgContainer{
        width: 30%;
    }
    .navImgContainer>img{
        width: 90%;
    }
    .navOptions{
        width: 70%;
    }
}

@media only screen and (min-width: 425px) and (max-width: 767px){
    .navImgContainer{
        width: 70%;
        height: 15vh;
    }
    .navImgContainer>img{
        width: 100%;
    }
    .navOptions{
        display: none;
    }
}
