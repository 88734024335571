.App {
    margin-top: 50px;
    text-align: center;
}

.title {
    margin-bottom: 50px;
    font-family: 'Cinzel Decorative', cursive;
    text-shadow: 1px 20px 25px rgba(151, 133, 108, 1);
    font-size: 30px;
}

/*.image_wrapper {*/
/*    height: 300px;*/
/*    width: 200px;*/
/*    background-color: #d4af37;*/
/*    margin: 0 30px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.marqImg{
    width: 80%;
    object-fit: cover;
    object-position: center;
}


/* Media Queries */

@media only screen and (max-width: 700px){
    .title{
        font-size:25px;
    }
}

@media only screen and (max-width: 500px){
    .title{
        font-size:20px;
    }
}

@media only screen and (max-width: 450px){
    .App{
        margin-top: 100px;
    }
}

@media only screen and (max-width: 300px){
    .title{
        width: 100%;
        height: fit-content;
        padding: 3% 0;
        font-size:15px;
        display: flex;
        flex-wrap: wrap;
        flex-flow: wrap;
        justify-content: center;
        align-items: center;
    }
    .title>h1{
        font-size: 30px;
    }
}
@media only screen and (max-width: 200px){
    .title>h1{
        font-size: 20px;
    }
}
