.CCHeading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
    color: #d4af37;
    color: black;
    text-shadow: 1px 20px 25px rgba(151, 133, 108, 1);
    font-size: 50px;
}

.CCimg {
    max-width: 12rem;
    width: fit-content;
    height: fit-content;
    margin-top: 5%;
    padding: 0 15px;
    /*object-fit: cover;*/
    /*background-size: cover;*/

}

.CCimg:last-of-type {
    padding-left: 0;
}

.CCinner {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 30vh;
    /*margin-bottom: 15%;*/
}

.CCwrapper {
    /*margin: 4%;*/
    position: absolute;
    display: flex;

}

.CCwrapper>section {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: swipe 20s linear infinite backwards;
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}


@media only screen and (min-width: 1440px){}
@media only screen and (max-width: 1440px) and (min-width: 1024px){}
@media only screen and (max-width: 1024px) {
    .CCHeading{
        /*background-color: red;*/
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-flow: wrap;
    }
}
@media only screen and (max-width: 768px){
    .CCContainer{
        margin-top:35%;
        margin-bottom: 15%;
        height: 40vh;
    }
    .CCHeading{
        width: 100%;
        font-size: 40px;
        margin: 10% 0;
    }
    .CCinner{
        margin-top: 25%;
    }
}
@media only screen and (max-width: 380px){
    .CCContainer{
        margin-top: 25%;
        /*margin-bottom: 15%;*/
        /*height: 60vh;*/
    }
    .CCHeading{
        width: 100%;
        font-size: 20px;
        margin: 3% 0;
    }
}
