.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}

.modal {
    position: fixed;
    top: 30vh;
    left: 10%;
    width: 80%;
    z-index: 100;
    overflow: hidden;
}

.header {
    background: #4f005f;
    background-color: red;
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header h2 {
    margin: 0;
    color: white;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 1rem;
}
.content>p{
    padding: 1rem;

}

.TandC{
    background-color: white;
    font-size: 15px;
    text-align: center;
    padding: 1rem;

}
.actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}

.linkClass{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: black;
    /*color: white;*/
}



@media (min-width: 768px) {
    .modal {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}
