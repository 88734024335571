:root {
    --min-size: 2px;
    --max-size: 20px;
}

* {
    box-sizing: border-box;
}

/*html, body, .backdrop {*/
/*    height: 100vh;*/
/*    width: 100%;*/
/*    margin: 0;*/
/*}*/

.backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    /*background: linear-gradient(45deg, #6200ea, #d500f9);*/
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dot {
    background: #085397;
    margin: 5px;
    animation-name: loader;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.dot:nth-child(1) {
    animation-delay: 0.2s;
}
.dot:nth-child(2) {
    animation-delay: 0.4s;
}
.dot:nth-child(3) {
    animation-delay: 0.6s;
}
.dot:nth-child(4) {
    animation-delay: 0.8s;
}
.dot:nth-child(5) {
    animation-delay: 1s;
}

@keyframes loader {
    from {
        width: var(--min-size);
        height: var(--min-size);
        border-radius: calc(var(--min-size) / 2);
    }
    to {
        width: var(--max-size);
        height: var(--max-size);
        border-radius: calc(var(--max-size) / 2);
    }
}
