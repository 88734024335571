/*.TeamMemberContainer{*/
/*    margin: 15% 5%;*/
/*    height:500px;*/
/*    width: 250px;*/
/*    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;*/
/*}*/

/*.TeamMemberCardContainer{*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-evenly;*/
/*}*/
/*.TeamMemberCardPicContainer{*/
/*    height: 20%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/
/*.TeamMemberCardPic{*/
/*    background-size: cover;*/
/*    background-repeat: no-repeat;*/
/*    background-position: center;*/
/*    height: 100px;*/
/*    width: 100px;*/
/*    border-radius: 75px;*/
/*}*/

/*.TeamMemberCardName{*/
/*    width: 100%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.TeamMemberPosition{*/
/*    width: 100%;*/
/*    display: flex;*/
/*    justify-content: space-around;*/
/*    align-items: center;*/
/*}*/

/*.TeamMemberPosition>hr{*/
/*    width: 30px;*/
/*    height: 2px;*/
/*}*/

/*.TeamMemberVision{*/
/*    height: fit-content;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    padding: 2% 0;*/
/*}*/

/*.TeamMemberVision>p{*/
/*    text-align: center;*/
/*}*/

/*.TeamMemberViewMore{*/
/*    height: 20%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.linkClass{*/
/*    display: flex;*/
/*    justify-content: space-evenly;*/
/*    align-items: center;*/
/*}*/



.TeamMemberContainer{
    margin: 15% 5%;
    height:500px;
    width: 250px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.TeamMemberCardContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.TeamMemberCardPicContainer{
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.TeamMemberCardPic{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    width: 100px;
    border-radius: 75px;
}

.TeamMemberCardName{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: bolder;
}

.TeamMemberPosition{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
}

.TeamMemberPosition>hr{
    width: 30px;
    height: 2px;
}

.TeamMemberVision{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2% 0;
    font-family: 'Josefin Sans', sans-serif;
}

.TeamMemberVision>p{
    text-align: center;
}

.TeamMemberViewMore{
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkClass{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}



