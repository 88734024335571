.TeamMembersContainer{
    /*margin-top: 5%;*/
    /*margin-bottom: 10%;*/
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
}

.TeamMemberCards{
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 2;
}

.leadership{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-flow: wrap;
    width:100%;
    height: fit-content;
    padding: 2%;
    text-align: center;
    font-family: 'Cinzel Decorative', cursive;
    font-size: 50px;
    text-shadow: 3px 11px 15px rgba(151, 133, 108, 1);
}

.leadership>p{
    cursor: pointer;
}
.leadership>p:hover{
    color: #085397;
}
@media only screen and (max-width: 600px){
    .TeamMemberCards{
        flex-direction: column;
    }
}

@media only screen and (max-width: 350px){
    .leadership>p{
        font-size: 40px;
    }
}

@media only screen and (max-width: 250px){
    .leadership>p{
        font-size: 30px;
    }
}
