@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');

/*body {*/
/*    box-sizing: border-box;*/
/*    color: #220f5f;*/
/*    font-family: 'Poppins', sans-serif;*/
/*}*/

.awardsHeading{
    margin-top: 3%;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.awardsHeading>p{
    font-family: 'Cinzel Decorative', cursive;
    color: black;
    text-shadow: 1px 20px 25px rgba(151, 133, 108, 1);
    font-size: 50px;
    cursor: pointer;
}

.awardsHeading>p:hover{
    color: #085397;
}

/*.container {*/
/*    width: 80%;*/
/*    !*max-width: 800px;*!*/
/*    padding: 0 10px;*/
/*    margin: 0 auto;*/
/*    !*margin-top: 70px;*!*/
/*    color: black;*/
/*}*/
/*table {*/
/*    border-collapse: collapse;*/
/*    width: 100%;*/
/*}*/

/*tr th {*/
/*    font-size: 18px;*/
/*    padding: 12px;*/
/*    border: 1px solid #eeeeee;*/
/*    text-align: left;*/
/*    background-color: rgba(217, 221, 146, 0.2);*/
/*}*/

/*tr td {*/
/*    border: 1px solid #eeeeee;*/
/*    text-align: left;*/
/*}*/

table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
}

table th,
table td {
    padding: .625em;
    text-align: center;
}

table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
}


tr:hover {
    background-color: #085397;
    color: white;
}


@media only screen and (max-width: 750px){
    .awardsHeading{
        width: 100%;
        flex-flow: wrap;
        flex-wrap: wrap;
        text-align: center;
    }
    .awardsHeading>p{
        font-size: 40px;
    }
}

@media screen and (max-width: 600px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table td:last-child {
        border-bottom: 0;
    }
}
@media only screen and (max-width: 500px){
    .awardsHeading>p{
        font-size: 30px;
    }
}
@media only screen and (max-width: 350px){
    .awardsHeading{
        width: 100%;
        /*font-size: 10px;*/
    }
    .awardsHeading>p{
        font-size: 30px;
    }
}
