.div-container{
    margin-top: 1%;
    height:75vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.div1{
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}


.arrow-container{
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 30px;
}

.arrow-container>*{
    cursor: pointer;
}

.text{
    border: 1px solid black;
    padding: 1% 5%;
    background-color: rgba(0,0,0,0.16);
    font-weight: 600;
    position: absolute;
    bottom: 10%;
}
.dots-container{
    width: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    bottom: 0;
}

.dots{
    cursor: pointer;
    color: white;
}


@media only screen and (max-width: 500px){
    .div-container{
        margin-top: 15%;
    }
}
@media only screen and (max-width: 425px){
    .arrow-container{
        display: none;
    }
    .div-container{
        padding: 0 2%;
    }
}
