/*TRAC*/
@import url('https://fonts.googleapis.com/css2?family=Irish+Grover&display=swap');

/*REPS*/
@import url('https://fonts.googleapis.com/css2?family=Slackey&display=swap');

/*NAV*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');


.optNavContainer{
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.rowOneContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.row1{
    height: fit-content;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trac-reps{
    height: 100%;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    color: #085397;
}

.trac{
    width: 100%;
    text-align: center;
    /*font-family:'Irish Grover', cursive;*/
    font-size:60px;
    font-weight: 500;
    color: red;
}

.rep{
    display: flex;
    width: 100% ;
    /*padding: 0 2%;*/
    justify-content: center;
    /*font-family: 'Montserrat Alternates', sans-serif;*/
    font-family: 'Acme', sans-serif;
    /*font-weight: bolder;*/
    font-size:15px;
    text-align: center;
}

.trac>span{
    font-size:50px;
}


.tracLogo{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row2{
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
}

.optNav{
    height: 100%;
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.optNav>*{
    text-decoration: none;
    /*font-family: 'Montserrat Alternates', sans-serif;*/
    font-weight: bolder;

}



/* Responsive Config */


@media only screen and (max-width: 960px){
    .row1{
        justify-content: space-around;
    }
}

@media only screen and (max-width: 768px){
    .rowOneContainer{
        justify-content: flex-start;
    }
    .row1{
        width: 70%;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 650px){
    .row1{
        width: 100%;
        flex-direction: column;
    }
    .optNavContainer{
        height: fit-content;
    }
    .trac-reps{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}



/*@media only screen and (min-height: 300px){*/
/*    .optNavContainer{*/
/*        height:150px;*/
/*    }*/
/*}*/
