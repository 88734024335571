.divContainer{
    /*height: 55vh;*/
    /*width: 15vw;*/
    height: 500px;
    width: 270px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: rgba(0,0,0,0.12);
    background-color: white;

    color: black;
    /*color: white;*/
    /*box-shadow: rgba(0, 245, 0, 0.56) 0px 22px 70px 4px;*/
    box-shadow: rgb(8, 83, 151) 0px 12px 50px 4px;
}

.logoContainer{
    /*background-color: #4f005f;*/
    /*height: 15vh;*/
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoContainer>img{
    max-width: 100%;
}

.headingContainer{
    padding: 0 3%;
    width: 100%;
    display: flex;
    justify-content: center;
    /*align-items: center;*/
}
.briefContainer{
    font-family: 'Josefin Sans', sans-serif;
    padding: 0 3%;
    margin-top: 2%;
    font-size: small;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.viewMoreContainer{
    margin: 5%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/*.viewMoreContainer>button{*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border: none;*/
/*    outline: none;*/
/*    background-color: transparent;*/
/*}*/

.viewMoreContainer>button{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    /*background-color: red;*/
    color: black;
    /*color: white;*/
    cursor: pointer;
}

.linkClass{
    text-decoration: none;
    font-style: italic;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: black;
    /*color: white;*/
}


/*@media only screen and (max-width: 1440px) and (min-width: 1100px){*/
    /*.divContainer{*/
    /*    width: 20vw;*/
    /*    margin-left: 5%;*/
    /*    margin-right: 5%;*/
    /*}*/
/*}*/
/*@media only screen and (max-width: 1440px) and (min-width: 1024px){*/
    /*.divContainer{*/
    /*    width: 25vw;*/
    /*    margin-left: 5%;*/
    /*    margin-right: 5%;*/
    /*}*/
/*}*/
/*@media only screen and (max-width: 1024px) and (min-width: 768px){*/
/*    .divContainer{*/
/*        background-color: #d4af37;*/
/*        width: 25vw;*/
/*        margin-left: 5%;*/
/*        margin-right: 5%;*/
/*    }*/
/*}*/
/*@media only screen and (max-width: 1024px){*/
    /*.divContainer{*/
    /*    width: 40vw;*/
    /*    margin-left: 5%;*/
    /*    margin-right: 5%;*/
    /*}*/
/*}*/
/*@media only screen and (max-width: 500px) and (min-width: 380px){*/
    /*.divContainer{*/
    /*    width: 60vw;*/
    /*    margin-left: 5%;*/
    /*    margin-right: 5%;*/
    /*}*/
/*}*/
/*@wmedia only screen and (max-width: 380px) and (min-width: 200px){*/
    /*.divContainer{*/
    /*    width: 70vw;*/
    /*}*/
/*}*/
@media only screen and (max-width: 270px) and (min-width: 100px){
    .divContainer{
        width: 100vw;
    }
}
