.footerContainerOuter{
    min-height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10% 0;
}
.footerContainerInner{
    height: fit-content;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4% 0;
}
.middleContainer{
    /*margin-top: 10%;*/
    /*margin-bottom: 1%;*/
    /*padding: 2% 0;*/
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(8, 83, 151) 0px 22px 120px 4px;

}
.footerRight{
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logoContainer{
    width: 100%;
    padding: 3%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.briefContainer{
    /*width:100%;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*!*font-size: x-small;*!*/
    /*color: red;*/
    /*font-family: 'Montserrat Alternates', sans-serif;*/
    /*font-weight: bolder;*/
    /*font-size:15px;*/

    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*font-size: x-small;*/
    color: red;
    /*font-family: 'Montserrat Alternates', sans-serif;*/
    /*font-weight: bolder;*/
    font-family: 'Acme', sans-serif;
    font-size:15px;
}

.briefContainer>p{
    text-align: center;
}

.footerLeft{
    padding: 0 3%;
    width: 50%;
}

.contactUsContainer > ul{
    list-style: none;
    color: black;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-flow: wrap;
}
.listItems{
    height: fit-content;
    width: 100%;
    display: flex;
    padding: 0.4rem 0;
    align-items: center;
}


.contactUsContainer > ul > li:nth-child(4){
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialIcons{
    width: 50%;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.socialIcons>a{
    color: black;
    text-decoration: none;
}

.footerCopyright{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerCopyright > hr {
    background-color: rgba(0, 245, 0, 0.56);
    background-color: #085397;
    width: 30vw;
    height: 3px;
}

.footerCopyright>p{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (max-width: 400px){
    .footerContainerOuter{
        margin-top: 35%;
    }
    .footerContainerInner{
        flex-direction: column;
    }
    .briefContainer{
        padding: 2% 0;
        text-align: center;
    }

    .footerLeft{
        padding: 0;
        width: 100%;
    }
    .listItems:nth-child(1){
        width: 100%;
        padding: 0;
    }
}
